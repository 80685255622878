<template>
  <AppModal v-model="innerVal" width="560px">
    <div>
      <!-- Header -->
      <div class="row p-3">
        <div class="col-10">
          <h5>ADD TO PROJECT QUOTE</h5>
        </div>
        <div class="col-1">
          <AppIcon
            hoverable
            name="close"
            class="ml-2"
            @click="innerVal = false"
          ></AppIcon>
        </div>
      </div>

      <!-- Projects -->
      <div class="overflow-auto" style="max-height: 350px">
        <AppCard
          v-for="(project, index) in cart"
          :key="`project-list-${index}`"
          hoverable
          class="pl-3 pr-3 rounded-0 shadow-none"
        >
          <div class="row" v-if="project.project_name.replace(/\s/g, '').length">
            <div class="col-2 p-3">
              <AppMediaViewer
                aspectRatio="1/1"
                width="100%"
                :src="project.thumbnail"
              ></AppMediaViewer>
            </div>
            <div class="col-8 d-flex flex-column justify-content-center">
              <p class="m-0 font-weight-5">{{ project.project_name }}</p>
              <p class="m-0 text-caption text-gray">
                {{ project.items.length }}
                {{ `Item${project.items.length > 1 ? 's' : ''}` }}
              </p>
            </div>
            <div class="col-1 d-flex align-items-center">
              <AppInputCheckbox
                hideError
                :radioValue="project.project_name"
                :value="chosenProjects.includes(project.project_name)"
                @input="(value) => setProject(value, project.project_name)"
              ></AppInputCheckbox>
            </div>
          </div>
        </AppCard>
      </div>

      <!-- Add new Project -->
      <div class="m-3 pt-3 pb-3 border-top border-bottom">
        <AppBtn
          v-if="!newProject"
          text
          prependIcon="add_filled"
          @click="newProject = true"
        >
          Add new Project
        </AppBtn>
        <AppInput
          v-else
          v-model="projectName.value"
          placeholder="Project Name"
          :validations="projectName.validations"
          @keyup.enter.native="addNewProject"
        ></AppInput>
      </div>
      <!-- Action Btn -->
      <div class="p-3 d-flex justify-content-end">
        <AppBtn
          v-if="!newProject"
          :disabled="chosenProjects.length < 1"
          @click="addToCart"
          >Done</AppBtn
        >
        <div v-else>
          <AppBtn outline @click="newProject = false">Cancel</AppBtn>
          <AppBtn
            class="ml-2"
            :disabled="projectName.value.length < 1"
            @click="addNewProject"
            >Save</AppBtn
          >
        </div>
      </div>
      <p v-if="error" class="pr-3 text-danger text-right">{{ error }}</p>
    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/shared/elements/AppModal.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppCard from '@/shared/elements/AppCard.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import validation from '@/shared/utils/validation';
import Cart from '@/shared/api/Cart';
import CartItems from '@/shared/api/CartItems';
import { mapGetters } from 'vuex';
import AppInputCheckbox from '../../../shared/elements/AppInputCheckbox.vue';

export default {
  name: 'ProductViewCheckoutModal',

  components: {
    AppModal,
    AppIcon,
    AppMediaViewer,
    AppCard,
    AppBtn,
    AppInput,
    AppInputCheckbox,
  },

  mixins: [_appDefaultInput],

  props: {
    item: { type: Object, default: null },
  },

  data() {
    return {
      newProject: false,
      chosenProjects: [],
      projectName: {
        value: '',
        validations: [validation.required()],
      },
      error: '',
    };
  },

  computed: {
    ...mapGetters('cart', ['cart']),
  },

  watch: {
    innerVal(val) {
      if (!val) {
        this.chosenProjects = [];
      }
    },
  },

  filters: {
    thumbnail(value) {
      return value?.thumbnail || null;
    },
  },

  methods: {
    setProject(value, projectName) {
      if (value) {
        this.chosenProjects.push(projectName);
      } else {
        const i = this.chosenProjects.findIndex(
          (projects) => projects === projectName
        );
        this.chosenProjects.splice(i, 1);
      }
    },
    async addToCart() {
      let item = this.item;
      item.product_variant_id =
        item.product_variant_id !== -1 ? item.product_variant_id : null;
      item.quantity_option =
        item.quantity_option !== -1 ? item.quantity_option : null;

      await this.chosenProjects.forEach((projectName) => {
        CartItems.postCartItem({
          project: projectName,
          item: item,
        }).then((response) => {
          this.$store.dispatch('cart/addToCart', {
            project: {
              id: response.data.cart.id,
              name: response.data.cart.project,
            },
            item: {
              ...item,
              ...{
                id: response.data.item.id,
                project_name: response.data.cart.project.replace(/\s/g, '').length ? response.data.cart.project : ' ',
                project_id: response.data.cart.id,
                quantity: response.data.item.quantity,
                quantity_name: response.data.item.quantity_option,
                variant_name: response.data.item?.data?.variant_name,
              },
            },
          });
        });
      });
      this.innerVal = false;
    },
    addNewProject() {
      if (this.projectName.value.length > 0) {
        if (
          this.cart.find((cart) => cart.project_name === this.projectName.value)
        ) {
          return (this.error = 'Project name already exists');
        }
        this.error = '';
        Cart.postCart({
          project: this.projectName.value,
        }).then(() => {
          this.$store.dispatch('cart/newProject', this.projectName.value);
        });
        this.newProject = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
